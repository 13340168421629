/*
 * @Description: eidt by xiaoyaozi
 * @Version: 2.0
 * @Autor: xiaoyaozi
 * @Date: 2020-06-28 13:38:34
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-22 11:24:15
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { BasicLayout, LayOutView, RouteView, NormalLayout } from '@/components/layouts'

Vue.use(VueRouter)
// meta - needLogin 为跳转的页面是否需要登录
const routes = [
  {
    path: '/',
    meta: {
      needLogin: false,
      needHeaderSearch: false
    },
    name: 'homePage',
    component: () => import('@/views/homePage/index')
  },
  {
    path: '/requirementHome',
    name: 'requirementHome',
    meta: {
      needLogin: true,
      needHeaderSearch: false
    },
    component: () => import('@/views/createNewRequirement/requirementHome')
  },
  {
    path: '/connectUs',
    name: 'connectUs',
    meta: {
      needLogin: true,
      needHeaderSearch: false
    },
    component: () => import('@/views/connectUs/index.vue')
  },
  {
    path: '/companyInfoPage',
    meta: {
      needLogin: false,
      needHeaderSearch: false
    },
    name: 'companyInfoPage',
    component: () => import('@/views/createNewRequirement/companyInfoPage')
  },
  {
    path: '/RootView',
    name: 'RootView',
    component: BasicLayout,
    children: [
      {
        path: '/createNewRequirement',
        name: 'createNewRequirement',
        meta: {
          needLogin: true,
          needHeaderSearch: false
        },
        component: () => import('@/views/createNewRequirement/index')
      }
    ]
  },
  {
    path: '/normal',
    name: 'normalPage',
    component: NormalLayout,
    redirect: '/PublishingRequirements',
    children: [
      {
        path: '/PublishingRequirements',
        meta: {
          needLogin: true,
          needHeaderSearch: true
        },
        name: 'PublishingRequirements',
        component: () => import('@/views/PublishingRequirements/index')
      },
      {
        path: '/ChangeOrder',
        meta: {
          needLogin: true,
          needHeaderSearch: true
        },
        name: 'ChangeOrder',
        component: () => import('@/views/PublishingRequirements/ChangeOrder')
      },
      {
        path: '/productInfo',
        meta: {
          needLogin: false,
          needHeaderSearch: true
        },
        name: 'productInfo',
        component: () => import('@/views/productInfo/index')
      },
      {
        path: '/searchResult',
        meta: {
          needLogin: false,
          needHeaderSearch: false
        },
        name: 'searchResult',
        component: () => import('@/views/searchResult/index')
      },
      {
        path: '/supplierInfo',
        meta: {
          needLogin: false,
          needHeaderSearch: false
        },
        name: 'supplierInfo',
        component: () => import('@/views/supplierInfo/index')
      },
      {
        path: '/ImproveInformation',
        meta: {
          needLogin: true,
          needHeaderSearch: true
        },
        name: 'ImproveInformation',
        component: () => import('@/views/ImproveInformation')
      },
      {
        path: '/editPartInfo',
        meta: {
          needLogin: true,
          needHeaderSearch: true
        },
        name: 'editPartInfo',
        component: () => import('@/views/PublishingRequirements/modules/editPartInfo.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: LayOutView,
    redirect: '/workSpace',
    children: [
      {
        path: '/workSpace',
        name: 'workSpace',
        meta: {
          needLogin: true,
          needSearch: true,
          title: '首页',
          icon: 'home'
        },
        component: () => import('@/views/workSpace/index')
      },
      {
        path: '/graphicalPlane',
        name: 'graphicalPlane',
        meta: {
          needLogin: true,
          needSearch: true,
          title: '工作台',
          icon: 'dashboard'
        },
        component: () => import('@/views/workSpace/graphicalPlane')
      },
      {
        path: '/requirementPool',
        name: 'requirementPool',
        meta: {
          needLogin: true,
          title: '公共需求池',
          newCountKey: 'requirementPoolCount',
          needSearch: true,
          icon: 'database'
        },
        component: () => import('@/views/requirement/requirementPool/index'),
      },
      {
        path: '/requirementPool/requirementPoolDetails',
        name: 'requirementPoolDetails',
        hidden: true,
        meta: {
          title: '公共需求池详情',
          needSearch: true
        },
        component: () => import('@/views/requirement/requirementPool/modules/requirementPoolDetails')
      },
      {
        path: '/requirement',
        name: 'requirement',
        meta: {
          title: '需求管理',
          needLogin: true,
          needSearch: true,
          newCountKey: 'requirementCount',
          otherKey: 'joinQuote',
          icon: 'compass'
        },
        component: RouteView,
        children: [

          {
            path: '/requirement/requirementList',
            name: 'requirementList',
            meta: {
              title: '需求列表',
              needSearch: true,
              needLogin: true,
              newCountKey: 'requirementCount',
              otherKey: 'joinQuote',
              // icon: 'unordered-list'
            },
            component: () => import('@/views/requirement/requirementList/modules/forUserAndPurchase')
          },
          {
            path: '/requirement/ForUserAndPurchaseDetails',
            name: 'ForUserAndPurchaseDetails',
            hidden: true,
            meta: {
              title: '需求详情',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/requirementList/modules/ForUserAndPurchaseDetails')
          },
          {
            path: '/requirement/quotePriceInfo',
            name: 'quotePriceInfo',
            hidden: true,
            meta: {
              title: '报价详情',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/requirementList/quotePriceInfo')
          },
          {
            path: '/requirement/designScheme',
            name: 'designScheme',
            hidden: true,
            meta: {
              title: '查看设计方案',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/orderManagement/modules/designScheme')
          },
          {
            path: '/requirement/BudgetPrice',
            name: 'BudgetPrice',
            hidden: true,
            meta: {
              title: '报价预算',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/orderManagement/modules/BudgetPrice')
          },
          {
            path: '/requirement/DetailedParameter',
            name: 'designScheme',
            hidden: true,
            meta: {
              title: '详细参数',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/orderManagement/modules/detailedParameter')
          },
        ]
      },
      {
        path: '/quotePriceManagement',
        name: 'quotePriceManagement',
        meta: {
          title: '报价管理',
          needLogin: true,
          needSearch: true,
          newCountKey: 'quoteCount',
          icon: 'dollar'
        },
        component: RouteView,
        children: [
          {
            path: '/quotePriceManagement/quotePriceList',
            name: 'quotePriceList',
            meta: {
              title: '报价列表',
              needSearch: true,
              newCountKey: 'quoteCount',
              needLogin: true,
            },
            component: () => import('@/views/quotePriceManagement/quotePriceList')
          },
          {
            path: '/quotePriceManagement/quotePriceDetailsView',
            name: 'quotePriceDetailsView',
            hidden: true,
            meta: {
              title: '报价详情',
              needSearch: true
            },
            component: () => import('@/views/quotePriceManagement/quotePriceDetails')
          },
          {
            path: '/quotePriceManagement/quotePriceDetails',
            name: 'quotePriceDetails',
            hidden: true,
            meta: {
              title: '报价详情',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/quotePriceManagement/quotePriceDetails')
          },
          {
            path: '/quotePriceManagement/quotePriceInfoView',
            name: 'quotePriceInfoView',
            hidden: true,
            meta: {
              title: '报价详情',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/requirement/requirementList/quotePriceInfo')
          },
          {
            path: '/quotePriceManagement/quotePriceForm',
            name: 'quotePriceForm',
            hidden: true,
            meta: {
              title: '填写报价',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/quotePriceManagement/quotePriceForm')
          },
          {
            path: '/quotePriceManagement/quotePriceOrderInfo',
            name: 'quotePriceOrderInfo',
            hidden: true,
            meta: {
              title: '报价方案',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/quotePriceManagement/quotePriceOrderInfo')
          }
        ]
      },
      {
        path: '/orderManagement',
        name: 'orderManagement',
        meta: {
          needLogin: true,
          needSearch: true,
          newCountKey: 'orderCount',
          title: '订单管理',
          icon: 'profile'
        },
        component: () => import('@/views/requirement/orderManagement/index')
      },
      {
        path: '/orderManagement/orderDetails',
        name: 'orderDetails',
        hidden: true,
        meta: {
          title: '订单详情',
          needSearch: true,
          needLogin: true,
        },
        component: () => import('@/views/requirement/orderManagement/modules/orderDetails')
      },
      {
        path: '/orderManagement/orderDetailsForUser',
        name: 'orderDetailsForUser',
        hidden: true,
        meta: {
          title: '订单详情',
          needSearch: true
        },
        component: () => import('@/views/requirement/orderManagement/modules/orderDetailsForUser')
      },
      {
        path: '/orderManagement/appraisePurchaser',
        name: 'appraisePurchaser',
        hidden: true,
        meta: {
          title: '评价（服务商/供应商）',
          needSearch: true,
          needLogin: true,
        },
        component: () => import('@/views/requirement/appraiseManagement/appraisePurchaser')
      },
      {
        path: '/orderManagement/appraiseForUser',
        name: 'appraiseForUser',
        hidden: true,
        meta: {
          title: '评价采购商',
          needSearch: true
        },
        component: () => import('@/views/requirement/appraiseManagement/appraiseForUser')
      },
      {
        path: '/messageManagement',
        name: 'messageManagement',
        meta: {
          title: '消息管理',
          needLogin: true,
          needSearch: true,
          icon: 'message'
        },
        component: RouteView,
        children: [
          {
            path: '/messageManagement/messageList',
            name: 'messageList',
            meta: {
              title: '消息列表',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/messageManagement/index')
          },
        ]
      },

      {
        path: '/businessManagement',
        name: 'businessManagement',
        meta: {
          title: '企业管理',
          needLogin: true,
          needSearch: true,
          icon: 'bank'
        },
        component: RouteView,
        children: [
          {
            path: '/businessManagement/companyInfo',
            name: 'companyInfo',
            meta: {
              title: '企业信息',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/businessManagement/companyInfo')
          },
          // {
          //   path: '/businessManagement/accountManagement/OrganizationInformation',
          //   name: 'OrganizationInformation',
          //   meta: {
          //     title: '组织信息',
          //     needSearch: true,
          //   },
          //   component: () => import('@/views/businessManagement/accountManagement/OrganizationInformation/index')
          // },
          // {
          //   path: '/businessManagement/accountManagement/OrganizationInformationInfoForm',
          //   name: 'OrganizationInformationInfoForm',
          //   hidden: true,
          //   meta: {
          //     title: '组织信息编辑',
          //     needSearch: true,
          //   },
          //   component: () => import('@/views/businessManagement/accountManagement/OrganizationInformation/modules/infoForm')
          // },
          // {
          //   path: '/businessManagement/accountManagement/EmployeeManagement',
          //   name: 'EmployeeManagement',
          //   meta: {
          //     title: '员工管理',
          //     needSearch: true,
          //   },
          //   component: () => import('@/views/businessManagement/accountManagement/EmployeeManagement/index')
          // },
          // {
          //   path: '/businessManagement/accountManagement/positionManagement',
          //   name: 'positionManagement',
          //   meta: {
          //     title: '职位信息',
          //     needSearch: true,
          //   },
          //   component: () => import('@/views/businessManagement/accountManagement/positionManagement/index')
          // },
          // {
          //   path: '/businessManagement/accountManagement/RolePermissions',
          //   name: 'RolePermissions',
          //   meta: {
          //     title: '角色权限',
          //     needSearch: true,
          //   },
          //   component: () => import('@/views/businessManagement/accountManagement/RolePermissions/index')
          // }
        ]
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        meta: {
          title: '账号管理',
          needLogin: true,
          needSearch: true,
          icon: 'user'
        },
        component: RouteView,
        children: [
          {
            path: '/userInfo/basicUserInfo',
            name: 'basicUserInfo',
            meta: {
              title: '账号信息',
              needSearch: true,
              needLogin: true,
            },
            component: () => import('@/views/userInfo/basicUserInfo/index')
          }
        ]
      },
    ]
  },
  {
    path: '/login',
    meta: {
      needLogin: false
    },
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      needLogin: false
    },
    component: () => import('@/views/404')
  },
  {
    path: '*',
    meta: {
      needLogin: false
    },
    redirect: '/404'
  }
]
export const BasicRoutes = routes.filter(i => i.name == 'dashboard')[0].children

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to, from, savePosition) {
    return {
      y: 0
    }
  }
})

export default router
