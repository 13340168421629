/*
 * @Description: 人员信息模块
 * @Version: 2.0
 * @Author: pony
 * @Date: 2020-07-20 15:20:54
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-14 16:47:19
 */
import { getRemindInfo } from '@/api/api'
const user = {
  state: {
    isLogin: false,
    userInfo: {},
    regionData: {
      regionId: '310000000000',
      regionIdName: '上海市',
      regionIdTwo: '310101000000',
      regionIdTwoName: '黄浦区'
    },
    infoCount: {
      orderCount: 0,
      requirementPoolCount: 0,
      requirementCount: 0,
      quoteCount: 0,
      joinQuote: 0
    }
  },
  getters: {
    isLogin(state) {
      state.isLogin = !!localStorage.getItem('isLogin')
      return state.isLogin
    },
    setUserInfo(state) {
      let info = localStorage.getItem('userInfo')
      if (state.isLogin) {
        info && (state.userInfo = JSON.parse(info))
        return state.userInfo
      } else {
        return {}
      }
    }
  },
  mutations: {
    setInfoCount(state, info) {
      console.log(info)
      state.infoCount = { ...state.infoCount, ...info };
    },
    changeRegionData(state, info) {
      state.regionData = info
    },
    CHANGE_LOGIN_STATE(state, value) {
      state.isLogin = value
    },
    setUserInfo(state, info) {
        state.userInfo = info
    }
  },
  actions: {
    login({ commit }, userinfo) {
      return new Promise((resolve, reject) => {
        localStorage.setItem('isLogin', true)
        commit('CHANGE_LOGIN_STATE', true)
        resolve({ isLogin: true })
      })
    },
    loginOut({ commit }) {
      return new Promise((resolve, reject) => {
        localStorage.clear()
        commit('CHANGE_LOGIN_STATE', false)
        commit('setUserInfo', {})
        resolve(true)
      })
    },
    changeInfoCount({commit}) {
      let keys = {
        PURCHASE: 'requirementCount',
        ORDER: 'orderCount',
        PURCHASE_POOL: 'requirementPoolCount',
        QUOTE: 'quoteCount',
        JOIN_QUOTE: 'joinQuote'
      }
      let infoCount = {};
      getRemindInfo().then(res => {
        const { success, data } = res;
        if (success) {
          data.map(i => {
            const { scene, dataCount } = i;
            infoCount[keys[scene]] = dataCount || 0;
          })
          commit('setInfoCount', infoCount)
        }
      })
    }
  }
}
export default user
