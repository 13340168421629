/*
 * @Description: 请求方式的集合
 * @Author: zhang zhen
 * @Date: 2023-02-20 09:45:52
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-09-15 11:09:49
 * @FilePath: /page-sass/src/api/manage.js
 */
import { axios } from '@/util/request'

// post
export function postAction(url, parameter) {
    return axios({
      url: url,
      method: 'post',
      data: parameter
    })
  }
  
  // post 通过query提取参数
  export function postQueryAction(url, parameter) {
    return axios({
      url: url,
      method: 'post',
      params: parameter
    })
  }
  // post method = {post | put}
  export function httpAction(url, parameter, method) {
    return axios({
      url: url,
      method: method,
      data: parameter
    })
  }
  
  // put
  export function putAction(url, parameter) {
    return axios({
      url: url,
      method: 'put',
      data: parameter
    })
  }
  
  // get
  export function getAction(url, parameter) {
    return axios({
      url: url,
      method: 'get',
      params: parameter
    })
  }
  
  // deleteAction
  export function deleteAction(url, parameter) {
    return axios({
      url: url,
      method: 'delete',
      params: parameter
    })
  }
  // 
  export function downGetFiles(url, parameter) {
    return axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      params: parameter
    })
  }
  // 文件下载
  export function downFiles(url, parameter) {
    return axios({
      url: url,
      method: 'post',
      responseType: 'blob',
      data: parameter
    })
  }
  