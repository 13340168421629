/*
 * @Description: eidt by xiaoyaozi
 * @Version: 2.0
 * @Autor: xiaoyaozi
 * @Date: 2020-06-28 13:38:34
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-30 20:43:46
 */ 
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueRouter from 'vue-router'
import store from './store'
import axios from 'axios'
import filters from './util/filter'
import lodash from 'lodash'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './permission'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)
Vue.use(Antd)
//过滤器
Object.keys(filters).forEach(k=> Vue.filter(k,filters[k]))
//声明事件bus
//重写push方法
const originalPush = vueRouter.prototype.push;//原始的push
vueRouter.prototype.push= function(location){
  return originalPush.call(this,location).catch(err=>err)
}
Vue.prototype.$_ = lodash
Vue.prototype.$axios = axios
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
