<!--
 * @Description: 程序主入口
 * @Version: 2.0
 * @Author: pony
 * @Date: 2020-06-28 13:38:34
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-21 12:01:50
-->
<template>
  <a-config-provide :locale="zh_CN">
    <div id="app">
      <router-view />
      <!-- <a-back-top>
        <div class="ant-back-top-inner">

        </div>
      </a-back-top> -->
    </div>
  </a-config-provide>
</template>
<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { ConfigProvider } from 'ant-design-vue'
import { mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    AConfigProvide: ConfigProvider
  },
  data() {
    return {
      zh_CN
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        console.log(route)
        this.changeInfoCount();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['changeInfoCount']),
  },
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-notice-wrapper {
  .ant-popover-arrow {
    display: none;
  }

  .header-title {
    display: flex;
    align-items: center;
    margin-bottom: 11px;

    .alarm {
      width: 24px;
      margin-right: 2px;
    }

    span {
      color: #FF6026;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .notice-content-item {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFFCC;
    margin-top: 4px;
    span.left-label {
      display: flex;
      align-items: center;
    }
    span.label {
      color: #000000D9;
      font-weight: 500;
      margin-right: 4px;
    }
    .showInfo {
      width: 76px;
      cursor: pointer;
    }
  }

  .ant-popover-inner-content {
    background: #F8FAFECC;
    padding: 25px;
    box-sizing: border-box;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  // min-height: 100vh;
  height: 100%;
  position: relative;
  background: #fff;
  // min-width: 1796px;
}

.icon-arrow-tag {
  width: 16px;
}

.coverSelector {
  width: 220px;

  .ant-popover-message-title {
    padding-left: 0;
  }

  .ant-popover-buttons {
    display: none;
  }
}

.formNavigateModalCover .ant-modal-content {
  background: url('./assets/modelCover.jpg') no-repeat;
  background-size: 100% 100%;
  height: 418px;

  .anticon-close {
    color: #595959;
  }

  .ant-modal-header {
    display: none;
  }
}

.mainMenus {
  border-right: none !important;

  .ant-menu-item>a {
    font-weight: 500;
    font-family: 'PingFang SC';
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-menu-submenu>.ant-menu {
    background-color: #F9F9F9;
  }

  .ant-menu-item-selected>a {
    color: #FF6E2D;
  }

  // 
  .ant-menu-item-selected {
    background: rgba(255, 125, 47, 0.1) !important;
  }
}

// 
.infoTab {
  .ant-tabs-left-bar {
    border: none !important;
    background-color: #F7F8FA;
    padding: 78px 40px 24px;
    border-radius: 8px;
  }

  .ant-tabs-nav-container {
    border-right: 1px solid #E8E9EB;
  }

  .ant-tabs-tab-active {
    background-color: #FFF2EA;
  }
}

.ant-back-top-inner {
  height: 48px;
  width: 48px;
  border-radius: 10px;
  background-image: url('~@/assets/backTag.png');
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.mainMenus {
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-tabs-tab-active {
  font-weight: 500;
}

.ant-tabs-tab {
  color: #131212;
}

.backEnd {
  .ant-tabs-bar {
    border-bottom: 1px solid #EFEFEF;
  }

  .ant-divider {
    background: #e3e3e3 !important;
  }

  .ant-table-placeholder {
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
  }
}

.ElevatorFormRef {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    padding: 0;
    border-bottom: none;
    background-color: transparent;
  }
}

.ant-btn-primary {
  text-shadow: none !important;
  box-shadow: none !important;
}

.last-card {
  td.ant-descriptions-item[colspan="3"] {
    padding-bottom: 0;
  }

  td.ant-descriptions-item[colspan="3"] span.ant-descriptions-item-content {
    width: 100% !important;

    .ant-divider {
      margin-bottom: 0;
      background: transparent !important;
    }

    .ant-divider-inner-text {
      position: relative;
    }

    .split-title {
      box-sizing: border-box;
      position: absolute;
      top: -30px;
      background-color: transparent !important;
      padding-right: 8px;
    }
  }

  td.ant-descriptions-item[colspan="3"] .ant-descriptions-item-no-label {
    display: none !important;
  }
}

.chart-date {
  font-size: 12px;
  line-height: 18px;
  color: #8C8C8C;
}

.sumInfo {
  font-size: 12px;
  line-height: 12px;
  color: #131212;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    &:last-of-type {
      margin-left: 15px;
    }
  }
}

.smart-item {
  line-height: 12px;
  font-size: 12px;
  color: #131212;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>span {
    margin-left: 15px;
  }

  .left-charts {
    display: flex;
    align-items: center;

    .round {
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
      width: 4px !important;
      height: 4px !important;
    }

    .line {
      display: inline-block;
      margin-right: 6px;
      width: 6px;
      height: 2px;
    }
  }
}

.mb-6 {
  margin-bottom: 6px;
}

span.newTag {
  display: inline-block;
  padding: 0 4px;
  font-size: 12px;
  color: #fff;
  background: #FF6026;
  border-radius: 4px;

  &.menus {
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    margin: auto 0 auto 8px;
  }
}
// .ant-menu-submenu-title {
//   & span {
//     position: relative;
//   }
// }
// .ant-menu-item {
//   &>a {
//     position: relative;
//   }
// }
.new_pointer {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #FF6026;
  border-radius: 50%;
  margin-left: 3px;
  margin-bottom: 6px;
  // position: absolute;
  // top: -8px;
  // right: -2px;
}

.uploadView {
  &>span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ant-upload {
      margin: 0 auto;
    }
  }
}
</style>
