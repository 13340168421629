<!--
 * @Description: 侧边菜单
 * @Author: zhang zhen
 * @Date: 2023-02-13 16:29:12
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-03-16 14:36:05
 * @FilePath: /page-sass/src/components/menu/LayoutAside.vue
-->
<template>
  <a-layout-sider v-model="collapsed" collapsible theme="light" :width="228" :trigger="null" class="left-bar">
    <div class="logos" :class="!collapsed && 'noCollasped'">
      <img src="/main-logo.png" alt="" class="large" v-if="!collapsed">
      <img src="~@/assets/small.png" alt="" class="small" v-else>
      <!-- <img src="~@/assets/tagIcon.png" alt="" class="tagIcon" v-if="!collapsed"> -->
    </div>
    <s-menu :collapsed="collapsed" :menu="menus" :theme="theme" @select="onSelect" :style="menuStyle">
    </s-menu>
  </a-layout-sider>
</template>

<script>
import SMenu from './index'
import { BasicRoutes } from '@/router'
import { createNewRoutes } from '@/util/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'LayoutAside',
  components: {
    SMenu,
  },
  data() {
    return {
      collapsed: false,
      menus: [],
      theme: "light",
      fixSideBar: true,
    }
  },
  computed: {
    menuStyle() {
      let style = { padding: '0' }
      if (this.fixSideBar) {
        style['height'] = '100%'
        style['overflow'] = 'auto'
        style['overflow-x'] = 'hidden'
        style['background-color'] = '#fff'
        style['color'] = '#000'
        style['font-weight'] = 500
      }
      return style
    },
    // menus() {
    //   const copyRoutes = [...BasicRoutes];
    //   let newRoutes = [], judgeKeys = [], tradeIdentity = this.setUserInfo().tradeIdentity;
    //   if (tradeIdentity != 0) { // 0供应商看需求池
    //     judgeKeys = ['requirementPool', 'quotePriceManagement', 'invitedMe']
    //   }
    //   if (tradeIdentity == 2) { // 2采购商 不能看方案管理和产品管理
    //     judgeKeys = [...judgeKeys, 'projectManagement', 'productManagement', 'quotePriceManagement']
    //   }
    //   console.log(judgeKeys, tradeIdentity)
    //   copyRoutes.map(i => {
    //     filterRoutesByUserIdentity(i, judgeKeys)
    //     newRoutes.push(i)
    //   })
    //   console.log(newRoutes)
    //   return newRoutes
    // }
  },
  created () {
    this.handleCreateMenus();
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    onSelect() {

    },
    handleCreateMenus() {    
      const copyRoutes = [...BasicRoutes];
      let newRoutes = [], judgeKeys = []; 
      const { tradeIdentity, businessId } = this.setUserInfo();
      if (tradeIdentity != 0) { // 0供应商看需求池
        judgeKeys = ['requirementPool', 'quotePriceManagement', 'invitedMe']
      }
      !businessId && (judgeKeys = [...judgeKeys, 'EmployeeManagement', 'positionManagement', 'RolePermissions'])
      newRoutes = createNewRoutes(copyRoutes, judgeKeys)
      return this.menus = newRoutes
    },
    changeCollapsed(e) {
      this.collapsed = e
    },
  },
}
</script>

<style lang="less" scoped>
.left-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  box-shadow: 1px 0px 2px 0px #0000001A;
}

.logos {
  // background-color: #fff;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.noCollasped {
    justify-content: flex-start;
  }

  img.large {
    width: 147px;
    margin-left: 25px;
  }
  .tagIcon {
    width: 76px;
    margin-left: 8px;
  }
  img.small {
    width: 30px;
  }
}
::v-deep .ant-layout-sider {
  background-color: #fff!important;
}
</style>
